import Vue from 'vue'
import Vuex from 'vuex'
import { apiClientDistributorship } from '@/services/axios'
import Cookies from 'js-cookie'
import router from '@/router'

Vue.use(Vuex)

export default {
  /** LOGIN NEW SERVICES */
  async LOGIN({ commit, dispatch, rootState }, payload) {
    try {
      commit('SET_STATE', {
        loading: true,
      })

      const app_data = rootState.app
      const { emailPhone, password } = payload

      const { data: response } = await apiClientDistributorship({
        method: 'post',
        url: '/user/v2/cmd/login',
        data: {
          channel_id: app_data.channel_id,
          user_login: emailPhone,
          user_password: password,
        },
      })

      const { data, token } = response

      const user_data = {
        ...data,
        buyer_id: data.id,
        level_model_id: +data.level_model_id,
        channel_id: +data.channel_id,
        area_id: +data.area_id,
        loyalty_channel_id: 1363,
      }

      Cookies.set('user_data', JSON.stringify(user_data))
      Cookies.set('auth_token', token)

      commit('SET_STATE', {
        accessToken: token, // set to cookies auth_token
        user_data,
      })

      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit('SET_STATE', {
        loading: false,
      })
    }
  },

  async GUEST_LOGIN({ commit, dispatch, rootState }) {
    return true
    // try {
    //   commit('SET_STATE', {
    //     loading: true,
    //   })

    //   const app_data = rootState.app
    //   const { data: response } = await apiClientDistributorship({
    //     method: 'GET',
    //     url: `/user/v2/query/login/guest/${app_data.channel_id}`,
    //     headers: {
    //       'Business-Id': app_data.business_id,
    //     },
    //   })

    //   const { data, token } = response

    //   const user_data = {
    //     ...data,
    //     buyer_id: data.id,
    //     level_model_id: +data.level_model_id,
    //     channel_id: +data.channel_id,
    //     area_id: +data.area_id,
    //     loyalty_channel_id: 625,
    //     change_login_req: data.change_login_req,
    //     seller: data.seller,
    //     customer_category_id: data.customer_category_id,
    //     price_area_id: data.price_area_id,
    //     user_type: data.user_type,
    //   }

    //   Cookies.set('user_data', JSON.stringify(user_data))
    //   Cookies.set('auth_token', token)

    //   commit('SET_STATE', {
    //     accessToken: token, // set to cookies auth_token
    //     user_data,
    //   })

    //   const currentRoute = router.currentRoute
    //   if (currentRoute.query.redirect) {
    //     await router.push(currentRoute.query.redirect)
    //   }

    //   return Promise.resolve(response)
    // } catch (error) {
    //   return Promise.reject(error)
    // } finally {
    //   commit('SET_STATE', {
    //     loading: false,
    //   })
    // }
  },

  async REGISTER ({ commit, rootState }, payload) {
    try {
      commit('SET_STATE', {
        loading: true,
      })

      const { type, userId, verifyType, formValue } = payload

      const { data: response } = await apiClientDistributorship({
        method: type === 'CHANGE_LOGIN_REQUEST' ? 'put' : 'post',
        url: `/user/v2/cmd/register${type === 'CHANGE_LOGIN_REQUEST' ? `/${userId}` : ''}`,
        data: {
          ...formValue,
          channel_id: rootState.app.channel_id,
          change_login_req: 'true',
          user_name: verifyType === 'phone' ? formValue.phone : formValue.email,
        },
      })

      return Promise.resolve(response)
    } catch (err) {
      return Promise.reject(err)
    } finally {
      commit('SET_STATE', {
        loading: false,
      })
    }
  },

  async SENTOTP ({ commit, rootState }, payload) {
    try {
      commit('SET_STATE', {
        loading: true,
      })

      const { verifyType, user_id, type } = payload

      const { data: response } = await setTimeout(apiClientDistributorship({
        method: 'post',
        url: `/user/v2/cmd/${verifyType === 'phone' ? 'sentotp' : 'sentemail'}`,
        data: {
          user_id: user_id,
          type: type,
        },
      }), 4000)

      return Promise.resolve(response)
    } catch (err) {
      return Promise.reject(err)
    } finally {
      commit('SET_STATE', {
        loading: false,
      })
    }
  },

  async VERIFYOTP ({ commit, rootState }, payload) {
    try {
      commit('SET_STATE', {
        loading: true,
      })

      const { verifyType, otp, user_id } = payload

      const { data: response } = await apiClientDistributorship({
        method: 'post',
        url: `/user/v2/cmd/${verifyType === 'phone' ? 'verifyphone' : 'verifyemail'}`,
        data: {
          user_id: user_id,
          otp: otp,
        },
      })

      return Promise.resolve(response)
    } catch (err) {
      return Promise.reject(err)
    } finally {
      commit('SET_STATE', {
        loading: false,
      })
    }
  },

  async UPDATEUSERNAME ({ commit, rootState }, payload) {
    try {
      commit('SET_STATE', {
        loading: true,
      })
      const user_data_cookies = Cookies.get('user_data') ? JSON.parse(Cookies.get('user_data')) : {}
      const { userType, userId, dataUpdate, isVerifier } = payload

      const { data: response } = await apiClientDistributorship({
        method: 'put',
        url: `/user/v2/cmd/private/${user_data_cookies.buyer_id}/user/${userId}/${userType}`,
        data: {
          [userType]: dataUpdate,
          is_default: isVerifier,
        },
      })

      return Promise.resolve(response)
    } catch (err) {
      return Promise.reject(err)
    } finally {
      commit('SET_STATE', {
        loading: false,
      })
    }
  },

  async UPLOADIMAGE({ commit, rootState, dispatch }, formData) {
    try {
      commit('SET_STATE', {
        loading: true,
      })
      const { data: response } = await apiClientDistributorship.post('/user/v2/cmd/customer/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit('SET_STATE', {
        loading: false,
      })
    }
  },

  async LINKCONFIRMATIONDATAREQ({ commit, rootState, dispatch }, payload) {
    try {
      commit('SET_STATE', {
        loading: true,
      })
      const { linkId } = payload
      const { data: response } = await apiClientDistributorship({
        method: 'get',
        url: `/user/v2/query/registration/${linkId}`,
      })
      return Promise.resolve(response)
    } catch (err) {
      const { response: errResponse } = err
      return Promise.reject(errResponse)
    } finally {
      commit('SET_STATE', {
        loading: false,
      })
    }
  },

  async REGISTERCONFIRMATION({ commit, rootState, dispatch }, { ...payload }) {
    try {
      commit('SET_STATE', {
        loading: true,
      })
      // changes using post only
      const user_data_cookies = Cookies.get('user_data') ? JSON.parse(Cookies.get('user_data')) : {}
      const changeLoginReq = user_data_cookies.change_login_req || false

      const { data: response } = await apiClientDistributorship({
        method: changeLoginReq ? 'put' : 'post',
        url: `/user/v2/cmd/confirm${changeLoginReq ? '/' + payload.user.id : ''}`,
        data: {
          ...payload,
        },
      })

      const { data, token } = response

      const user_data = {
        buyer_id: data.id,
        first_name: data.first_name,
        last_name: data.last_name,
        store_type: data.store_type,
        area_id: +data.area_id,
        buyer_user_id: data.buyer_user_id,
        level_model_id: +data.level_model_id,
        store_name: data.store_name,
        phone: data.phone,
        email: data.email,
        channel_id: +data.channel_id,
        customer_category_id: data.customer_category_id,
        price_area_id: data.price_area_id,
        user_type: data.user_type,
      }

      Cookies.set('user_data', JSON.stringify(user_data))
      Cookies.set('auth_token', token)

      commit('SET_STATE', {
        accessToken: token, // set to cookies auth_token
        user_data,
      })

      return Promise.resolve(response)
    } catch (err) {
      return Promise.reject(err)
    } finally {
      commit('SET_STATE', {
        loading: false,
      })
    }
  },
  async SENDEMAILCHANGEPASSWORD({ commit, rootState, dispatch }, { ...payload }) {
    try {
      commit('SET_STATE', {
        loading: true,
      })

      const app_data = rootState.app
      const { data: response } = await apiClientDistributorship({
        method: 'put',
        url: '/user/v2/cmd/passwd',
        data: {
          ...payload,
          channel_id: app_data.channel_id,
        },
      })

      Cookies.set('change_password_state', response)

      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit('SET_STATE', {
        loading: false,
      })
    }
  },
  async CONFIRMLINKFORGOTPASSWORD({ commit, rootState, dispatch }, { hashId, token }) {
    try {
      commit('SET_STATE', {
        loading: true,
      })
      const change_password_state = Cookies.get('change_password_state') && JSON.parse(Cookies.get('change_password_state'))
      const { data: response } = await apiClientDistributorship({
        method: 'get',
        url: '/user/cmd/passwd',
        params: {
          email: change_password_state && change_password_state.email,
          hashId,
          token,
        },
      })

      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit('SET_STATE', {
        loading: false,
      })
    }
  },
  async CHANGEPASSWORD({ commit, dispatch, rootState }, { token, ...payload }) {
    try {
      commit('SET_STATE', {
        loading: true,
      })
      const { data: response } = await apiClientDistributorship({
        method: 'put',
        url: '/user/v2/cmd/resetpasswd',
        data: {
          ...payload,
        },
        headers: {
          'Authorization': token,
        },
      })

      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit('SET_STATE', {
        loading: false,
      })
    }
  },
  async RESETPASSWORD({ commit, dispatch, rootState }, { ...payload }) {
    try {
      commit('SET_STATE', {
        loading: true,
      })
      const { data: response } = await apiClientDistributorship({
        method: 'put',
        url: '/user/v2/cmd/private/passwd',
        data: {
          ...payload,
        },
      })

      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit('SET_STATE', {
        loading: false,
      })
    }
  },

  async LOGOUT({ commit, rootState, dispatch }) {
    commit('SET_STATE')
    commit('dashboard/SET_STATE', null, { root: true })
    commit('LOGOUT')

    // TODO: The total cart should calculate automatically based on cart items
    commit('purchase/SET_TOTAL_CART', 0, { root: true })
    await dispatch('GUEST_LOGIN')
    window.location.reload()
  },

  async LOGOUTWITHOUTRELOAD({ commit, rootState, dispatch }) {
    commit('SET_STATE')
    commit('dashboard/SET_STATE', null, { root: true })
    commit('LOGOUT')

    // TODO: The total cart should calculate automatically based on cart items
    commit('purchase/SET_TOTAL_CART', 0, { root: true })
    await dispatch('GUEST_LOGIN')
  },

  async GETDETAILUSER({ commit, rootState, dispatch }, { userId }) {
    try {
      commit('SET_STATE', {
        loading: true,
      })
      const { data: response } = await apiClientDistributorship({
        method: 'get',
        url: `/user/v2/query/user/${userId}`,
      })

      return Promise.resolve(response)
    } catch (err) {
      const { response: errResponse } = err
      return Promise.reject(errResponse)
    } finally {
      commit('SET_STATE', {
        loading: false,
      })
    }
  },

  async GUESTREGISTERBUYERID({ commit, rootState, dispatch }, { ...payload }) {
    try {
      commit('SET_STATE', {
        loading: true,
      })
      const { data: response } = await apiClientDistributorship({
        method: 'post',
        url: '/user/v2/cmd/guest/create',
        data: {
          ...payload,
        },
      })

      return Promise.resolve(response)
    } catch (err) {
      const { response: errResponse } = err
      return Promise.reject(errResponse)
    } finally {
      commit('SET_STATE', {
        loading: false,
      })
    }
  },

  async GETDETAILDISTRIBUTOR({ commit, rootState, dispatch }, { sellerId }) {
    try {
      commit('SET_STATE', {
        loading: true,
      })
      const { data: response } = await apiClientDistributorship({
        method: 'get',
        url: `/user/v2/query/distributors/${sellerId}`,
      })

      return Promise.resolve(response)
    } catch (err) {
      const { response: errResponse } = err
      return Promise.reject(errResponse)
    } finally {
      commit('SET_STATE', {
        loading: false,
      })
    }
  },
}
